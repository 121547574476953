import type { SliderRepository } from "./sliderRepository";
import { getSlider } from "../../data/sliderService";

export function sliderRepositoryImpl(): SliderRepository {
  return {

      async loadSlider() {
        const data = await getSlider();
        return data;
      }
      
  }
}
import React from 'react';
//import { useTranslation } from "react-i18next";
//import { useNavigate } from 'react-router-dom';
//import routes from '../../routes/routes';

export default function Navbar({ model }: { model: {
  logo: string[];
  pages: string[];
  buttons: {
      name: string;
      action: null;
  }[];
  terms: string; 
  policy: string
} }): JSX.Element {

  return (
    <header 
      id="navbar-section" 
      className="navbar-container d-flex flex-row justify-content-between align-items-center"
    >
      <div className="navbar-container-left d-flex flex-row align-items-center">
        <h2>{ model.logo[0] }<span>{ model.logo[1] }</span></h2>
      </div>
      <div className="navbar-container-right d-flex flex-row align-items-center">
        { model.pages.map((item, index) => {
          return (
            <div key={ index }>{ item }</div>
          )
        })}
        <div className="navbar-container-right-buttons d-flex flex-row align-items-center">
          <button>{ model.buttons[0].name }</button>
          <button>{ model.buttons[1].name }</button>
        </div>
      </div>
    </header>
  )

}

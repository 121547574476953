import React from "react";

function Twitter(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29.034" height="29.034" viewBox="0 0 29.034 29.034">
      <path id="Icon_awesome-twitter-square" data-name="Icon awesome-twitter-square" d="M25.923,2.25H3.111A3.112,3.112,0,0,0,0,5.361V28.173a3.112,3.112,0,0,0,3.111,3.111H25.923a3.112,3.112,0,0,0,3.111-3.111V5.361A3.112,3.112,0,0,0,25.923,2.25ZM22.754,12.542c.013.181.013.369.013.551A12.011,12.011,0,0,1,10.674,25.186,12.038,12.038,0,0,1,4.148,23.28a8.955,8.955,0,0,0,1.024.052,8.522,8.522,0,0,0,5.275-1.815,4.256,4.256,0,0,1-3.973-2.949,4.581,4.581,0,0,0,1.918-.078,4.251,4.251,0,0,1-3.4-4.174v-.052a4.248,4.248,0,0,0,1.918.538,4.242,4.242,0,0,1-1.892-3.539A4.2,4.2,0,0,1,5.593,9.12a12.069,12.069,0,0,0,8.762,4.446,4.26,4.26,0,0,1,7.252-3.882A8.329,8.329,0,0,0,24.3,8.66,4.239,4.239,0,0,1,22.437,11a8.459,8.459,0,0,0,2.45-.661A8.947,8.947,0,0,1,22.754,12.542Z" transform="translate(0 -2.25)" fill="#fff"/>
    </svg>
  );
}

export default Twitter;
import React from "react";
import { useTranslation } from "react-i18next";

function useNavbarViewModel(): {
  logo: string[];
  pages: string[];
  buttons: {
      name: string;
      action: null;
  }[];
  terms: string;
  policy: string;
} {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");
  
  return {
    logo: ["tickets", "Booster"],
    pages: [t('navbar.page1'), t('navbar.page2'), t('navbar.page3')],
    buttons: [{ name: t('navbar.button1'), action: null}, { name: t('navbar.button2'), action: null}],
    terms: t('landing.terms'),
    policy: t('landing.privacy'),
  };
  
}

export { useNavbarViewModel };
import React from "react";
import Navbar from "../components/shared/Navbar";
import LandingFirstComponent from "../components/landing/LandingFirstComponent";
import LandingSliderComponent from "../components/landing/LandingSliderComponent";
import LandingSecondComponent from "../components/landing/LandingSecondComponent";
import LandingThirdComponent from "../components/landing/LandingThirdComponent";
import { useLandingPageViewModel } from "../viewModels/landingPageViewModel";
import { useNavbarViewModel } from "../viewModels/navbarViewModel";
import LandingFourthComponent from "../components/landing/LandingFourthComponent";
import LandingFifthComponent from "../components/landing/LandingFifthComponent";
import Footer from "../components/shared/Footer";

export default function LandingPageView() {

  return (
    <>
      <Navbar model = { useNavbarViewModel() }/>
      <main className="landing-main d-flex flex-column justify-content-center align-items-center">
        <LandingFirstComponent model = { useLandingPageViewModel() }/>
        <LandingSecondComponent model = { useLandingPageViewModel() }/>
        <LandingThirdComponent model = { useLandingPageViewModel() }/>
        <LandingFourthComponent model = { useLandingPageViewModel() }/>
        <LandingFifthComponent model = { useLandingPageViewModel() }/>
        <Footer model = { useNavbarViewModel() }/>
        {/* {/*<LandingSliderComponent model = { useLandingPageViewModel() }/> */}
      </main>
    </>
  )

}

import React from "react";

function Facebook(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32.145" height="31.95" viewBox="0 0 32.145 31.95">
      <path id="Icon_awesome-facebook" data-name="Icon awesome-facebook" d="M32.707,16.635A16.072,16.072,0,1,0,14.124,32.513V21.281H10.041V16.635h4.083V13.094c0-4.028,2.4-6.253,6.071-6.253a24.735,24.735,0,0,1,3.6.314v3.953H21.765a2.323,2.323,0,0,0-2.619,2.51v3.017H23.6l-.713,4.646H19.146V32.513A16.078,16.078,0,0,0,32.707,16.635Z" transform="translate(-0.563 -0.563)" fill="#fff"/>
    </svg>
  );
}

export default Facebook;
import React, { useEffect, useState } from 'react';
import { Slider } from '../../../domain/models/sliderEntity';
import VideoFeatures from '../../../assets/video_features.mp4';
import Explore1 from '../../../assets/explore_1.png';
import Explore2 from '../../../assets/explore_2.png';
import Explore3 from '../../../assets/explore_3.png';
import Explore4 from '../../../assets/explore_4.png';
import Explore5 from '../../../assets/explore_5.png';
import ExploreBg1 from '../../../assets/explorebg1.png';
import ExploreBg2 from '../../../assets/explorebg2.png';
import Logo1 from '../../../assets/logo1.png';
import Logo2 from '../../../assets/logo2.png';
import Logo3 from '../../../assets/logo3.png';
import Logo4 from '../../../assets/logo4.png';
import Logo5 from '../../../assets/logo5.png';
import Logo7 from '../../../assets/logo7.png';
import Logo8 from '../../../assets/logo8.png';
import Centripetal from '../../../assets/centripetal.png';

export default function LandingFifthComponent({ model }: { 
  model: { getStarted: string; magicTitle: string, magicText: string, pricingTitle: string, pricingText: string;
    successTitle: string, successText: string, journey: string, journeyButton: string,
    limitlessTitle: string, limitlessText: string, limitlessTextSpan: string,
    limitless1: string, limitless2: string, limitless3: string, limitless4: string, limitless5: string,
    slider: Slider | undefined; isLoading: boolean; getPricing: () => void } })
  : JSX.Element {
  
    const [sliderValue, setSliderValue] = useState<number>(0);
    const [item, setItem] = useState<{
      value: number | undefined;
      commission: number | undefined;
    } | undefined>(undefined);

    
    
  useEffect(() => {
    model.getPricing();
  }, []);
  
  useEffect(() => {
    setItem({ value: model.slider?.value[0], commission: model.slider?.commission[0]})
  }, [model.slider]);
  
  useEffect(() => {
    if (sliderValue >= 0 && sliderValue < 20) setItem({ value: model.slider?.value[0], commission: model.slider?.commission[0],})
    if (sliderValue >= 20 && sliderValue < 40) setItem({ value: model.slider?.value[1], commission: model.slider?.commission[1],})
    if (sliderValue >= 40 && sliderValue < 60) setItem({ value: model.slider?.value[2], commission: model.slider?.commission[2],})
    if (sliderValue >= 60 && sliderValue < 80) setItem({ value: model.slider?.value[3], commission: model.slider?.commission[3],})
    if (sliderValue >= 80 && sliderValue <= 100) setItem({ value: model.slider?.value[4], commission: model.slider?.commission[4],})
  }, [sliderValue]);


  const explorer = [{id: 0, img: Explore1, text: model.limitless1},
    {id: 1, img: Explore2, text: model.limitless2},
    {id: 2, img: Explore3, text: model.limitless3},
    {id: 3, img: Explore4, text: model.limitless4},
    {id: 4, img: Explore5, text: model.limitless5}
  ]

  const handleChangeSlider = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSliderValue(Number(e.target.value))
  }


  return (
    <>
      <section 
        id="magic-landing-section" 
        className="magic-landing-container d-flex flex-column align-items-center"
      >
        <div className="magic-landing d-flex flex-column justify-content-center align-items-center">
          <h2>{ model.magicTitle }</h2>
          <p>{ model.magicText }</p>
          <div className="magic-landing-video d-flex justify-content-center align-items-center">
            <video controls width="auto" height="auto" title="video-landing-video" 
              className="video-landing-video" src={ VideoFeatures } 
            ></video>
          </div>
        </div>
      </section>
      <section 
        id="pricing-landing-section" 
        className="pricing-landing-container d-flex flex-column align-items-center"
      >
        <div className="pricing-landing d-flex flex-column justify-content-center align-items-center">
          <h2>{ model.pricingTitle }</h2>
          <p>{ model.pricingText }</p>
          <div className="pricing-landing-slide d-flex flex-column justify-content-between align-items-center">
            <div className="pricing-landing-slide-top d-flex flex-column justify-content-center align-items-center">
              <h3>NUMBER OF TICKETS</h3>
              <input
                onChange={handleChangeSlider}
                type="range"
                className="form-range"
                min="0"
                max="100"
                step="25"
                value={sliderValue}
              />
              <div className="pricing-landing-slide-top-values d-flex justify-content-between align-items-center">
                <p className={`${sliderValue >= 0 && sliderValue < 20 ? "active" : ""}`}>{ model.slider?.value[0] }</p>
                <p className={`${sliderValue >= 20 && sliderValue < 40 ? "active" : ""}`}>{ model.slider ? model.slider?.value[1] > 999 ? `${model.slider?.value[1]/1000}k` : model.slider?.value[1] : null }</p>
                <p className={`${sliderValue >= 40 && sliderValue < 60 ? "active" : ""}`}>{ model.slider ? model.slider?.value[2] > 999 ? `${model.slider?.value[2]/1000}k` : model.slider?.value[2] : null }</p>
                <p className={`${sliderValue >= 60 && sliderValue < 80 ? "active" : ""}`}>{ model.slider ? model.slider?.value[3] > 999 ? `${model.slider?.value[3]/1000}k` : model.slider?.value[3] : null }</p>
                <p className={`${sliderValue >= 80 && sliderValue <= 100 ? "active" : ""}`}>{ model.slider ? model.slider?.value[4] > 999999 ? `${model.slider?.value[4]/1000000}M` : model.slider?.value[4] : null }</p>
              </div>
            </div>
            <div className="pricing-landing-slide-bottom d-flex justify-content-center align-items-center">
              <img src={ Centripetal } alt={`centripetal`} className="pricing-landing-centripetal-1"/>
              <img src={ Centripetal } alt={`centripetal`} className="pricing-landing-centripetal-2"/>
                <h3>{item?.commission}</h3>
              <div className="pricing-landing-slide-bottom-text d-flex flex-column justify-content-center">
                <p>Per ticket <span>sold</span></p>
                <p>Only pay if you sell</p>
              </div>
            </div>
          </div>
          <button>{ model.getStarted }</button>
        </div>
      </section>
      <section 
        id="explore-landing-section" 
        className="explore-landing-container d-flex flex-column align-items-center"
      >
        <div className="explore-landing d-flex flex-column justify-content-center align-items-center">
          <h2>{ model.limitlessTitle }</h2>
          <p>{ model.limitlessText }<span>{ model.limitlessTextSpan }</span></p>
          <div className="explore-landing-box d-flex flex-wrap justify-content-around">
            { explorer.map(item => {
              return (
                <div key={ item.id } className="explore-landing-box-item d-flex flex-column align-items-center">
                  <img src={ item.img } alt={`explore-landing-${item.id}`} className=""/>
                  <p>{ item.text }</p>
                </div>
              )
            })}
          </div>
        </div>
        <img src={ ExploreBg1 } alt={`explore`} className=""/>
        <img src={ ExploreBg2 } alt={`explore`} className=""/>
      </section>
      <section className="main-landing-cut-1-reverse d-flex flex-column justify-content-center align-items-center"></section>
      <section 
        id="inspire-landing-section" 
        className="inspire-landing-container inspire-landing-container-1 d-flex flex-column align-items-center"
      >
        <div className="inspire-landing d-flex flex-column justify-content-center align-items-center">
          <h2>{ model.successTitle }</h2>
          <p>{ model.successText }</p>
        </div>
      </section>
      <div className="inspire-landing-band d-flex justify-content-center align-items-center">
        <div className="inspire-landing-band-box d-flex justify-content-between align-items-center">
          <img src={ Logo1 } alt={`logos-landing-1`} className=""/>
          <img src={ Logo2 } alt={`logos-landing-2`} className=""/>
          <img src={ Logo3 } alt={`logos-landing-3`} className=""/>
          <img src={ Logo4 } alt={`logos-landing-4`} className=""/>
          <img src={ Logo5 } alt={`logos-landing-5`} className=""/>
          {/*<img src={ Logo6 } alt={`logos-landing-6`} className=""/>*/}
          <img src={ Logo7 } alt={`logos-landing-7`} className=""/>
          <img src={ Logo8 } alt={`logos-landing-8`} className=""/>
        </div>
      </div>
      <section 
        id="inspire-landing-section-alt" 
        className="inspire-landing-container inspire-landing-container-2 d-flex flex-column align-items-center"
      >
        <img src={ Centripetal } alt={`centripetal`} className="inspire-landing-centripetal"/>
        <div className="inspire-landing d-flex flex-column justify-content-center align-items-center">
          <h2>{ model.journey }</h2>
          <button>{ model.journeyButton }</button>
        </div>
      </section>
      <section className="main-landing-cut-1 d-flex flex-column justify-content-center align-items-center"></section>
    </>
  )

}

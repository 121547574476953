import React from 'react';
import PoweredBy from '../../../assets/powered_by.png';
import Instagram from '../icons/Instagram';
import Facebook from '../icons/Facebook';
import Twitter from '../icons/Twitter';
import LinkedIn from '../icons/LinkedIn';

export default function Footer({ model }: { model: {
  logo: string[];
  pages: string[];
  buttons: {
      name: string;
      action: null;
  }[];
  terms: string; 
  policy: string
} }): JSX.Element {

  return (
    <footer 
      id="footer-landing-section" 
      className="footer-landing-container d-flex justify-content-between align-items-start"
    >
      <div className="footer-landing-powered d-flex justify-content-start align-items-center">
        <img src={ PoweredBy } alt={`ready-landing-section`} className=""/>
      </div>
      <div className="footer-landing-logo d-flex flex-column justify-content-center align-items-center">
        <h2>{ model.logo[0] }<span>{ model.logo[1] }</span></h2>
        <div className="footer-landing-terms d-flex flex-column justify-content-center align-items-center">
          <h4>{ model.terms }</h4>
          <h4>{ model.policy }</h4>
        </div>
      </div>
      <div className="footer-landing-social d-flex justify-content-end align-items-center">
        <Instagram/>
        <Facebook/>
        <Twitter/>
        <LinkedIn/>
      </div>
    </footer>
  )

}

import React from "react";

function Features6Icon(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75">
      <g id="Grupo_233" data-name="Grupo 233" transform="translate(-1416 -4753)">
        <circle id="Elipse_14" data-name="Elipse 14" cx="37.5" cy="37.5" r="37.5" transform="translate(1416 4753)" fill="#ddebff"/>
        <path id="Icon_awesome-check-double" data-name="Icon awesome-check-double" d="M29.015,10.043,26.739,7.768a1.373,1.373,0,0,0-1.948,0L11.031,21.529l-6.4-6.406a1.373,1.373,0,0,0-1.948,0L.4,17.4a1.38,1.38,0,0,0,0,1.954l9.653,9.658a1.373,1.373,0,0,0,1.948,0L29.009,12A1.385,1.385,0,0,0,29.015,10.043Zm-18.633,6.09a.912.912,0,0,0,1.3,0L23.631,4.171a.922.922,0,0,0,0-1.3l-2.6-2.6a.912.912,0,0,0-1.3,0l-8.7,8.7L7.848,5.786a.912.912,0,0,0-1.3,0l-2.6,2.6a.922.922,0,0,0,0,1.3Z" transform="translate(1438.794 4775.791)" fill="#196ef8"/>
      </g>
    </svg>
  );
}

export default Features6Icon;
import React from 'react';
import Features1Icon from '../icons/Features1Icon';
import Features2Icon from '../icons/Features2Icon';
import Features3Icon from '../icons/Features3Icon';
import Features4Icon from '../icons/Features4Icon';
import Features5Icon from '../icons/Features5Icon';
import Features6Icon from '../icons/Features6Icon';
import Centripetal from '../../../assets/centripetal.png';

export default function LandingFourthComponent({ model }: { 
  model: { featuresTitle: string, featuresTitle1: string, featuresText1: string, 
    featuresTitle2: string, featuresText2: string, featuresTitle3: string, featuresText3: string, 
    featuresTitle4: string, featuresText4: string, featuresTitle5: string, featuresText5: string, 
    featuresTitle6: string, featuresText6: string } })
  : JSX.Element {

  const features = [{id: 0, icon: <Features1Icon/>, title: model.featuresTitle1, text: model.featuresText1},
    {id: 1, icon: <Features2Icon/>, title: model.featuresTitle2, text: model.featuresText2},
    {id: 2, icon: <Features3Icon/>, title: model.featuresTitle3, text: model.featuresText3},
    {id: 3, icon: <Features4Icon/>, title: model.featuresTitle4, text: model.featuresText4},
    {id: 4, icon: <Features5Icon/>, title: model.featuresTitle5, text: model.featuresText5},
    {id: 15, icon: <Features6Icon/>, title: model.featuresTitle6, text: model.featuresText6}
  ]

  return (
    <section 
      id="features-landing-section" 
      className="features-landing-container d-flex flex-column align-items-center"
    >
      <div className="features-landing d-flex flex-column justify-content-center align-items-center">
        <h2>{ model.featuresTitle }</h2>
        <img src={ Centripetal } alt={`centripetal`} className="features-landing-centripetal"/>
        <div className="features-landing-box d-flex flex-wrap justify-content-around">
          { features.map(item => {
            return (
              <div key={ item.id } className="features-landing-box-item d-flex flex-column align-items-center">
                { item.icon }
                <div className="features-landing-box-item-text d-flex flex-column align-items-center">
                  <h5>{ item.title }</h5>
                  <p>{ item.text }</p>
                </div>
              </div>
            )
          })}
        </div>
        {/*<div className="features-landing-box features-landing-box-back d-flex flex-wrap justify-content-around">
        </div>*/}
      </div>
    </section>
  )

}

import React from "react";

function Features1Icon(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75">
      <g id="Grupo_236" data-name="Grupo 236" transform="translate(-430 -4434)">
        <circle id="Elipse_9" data-name="Elipse 9" cx="37.5" cy="37.5" r="37.5" transform="translate(430 4434)" fill="#ddebff"/>
        <path id="Icon_awesome-shield-alt" data-name="Icon awesome-shield-alt" d="M24.884,4.415,14.758.2a2.539,2.539,0,0,0-1.946,0L2.686,4.415A2.529,2.529,0,0,0,1.125,6.751c0,10.469,6.039,17.7,11.682,20.057a2.539,2.539,0,0,0,1.946,0c4.52-1.883,11.687-8.386,11.687-20.057A2.532,2.532,0,0,0,24.884,4.415Zm-11.1,19.123L13.783,3.444,23.06,7.31c-.174,7.985-4.33,13.77-9.272,16.228Z" transform="translate(453.218 4457.498)" fill="#196ef8"/>
      </g>
    </svg>
  );
}

export default Features1Icon;
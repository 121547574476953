import React from 'react';

export default function LandingFirstComponent({ model }: { 
  model: { title: string; subtitle: string; getStarted: string; } })
  : JSX.Element {

  return (
    <>
      <section 
        id="main-landing-section" 
        className="main-landing-container d-flex flex-column align-items-center"
      >
        <div className="main-landing-text d-flex flex-column justify-content-center align-items-center">
          <h1>{ model.title }</h1>
          <h2>{ model.subtitle }</h2>
        </div>
        <div className="main-landing-buttons d-flex flex-column justify-content-center align-items-center">
          <button>{ model.getStarted }</button>
        </div>
      </section>
      <section className="main-landing-cut d-flex flex-column justify-content-center align-items-center"></section>
    </>
  )

}

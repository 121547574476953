// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*:focus {
  outline: none;
}

button:hover, input[type=submit]:hover {
  opacity: 0.8;
}

button:active, input[type=submit]:active {
  opacity: 0.6;
}

button:disabled:active, input[type=submit]:disabled:active,
button:disabled:hover, input[type=submit]:disabled:hover {
  opacity: 0.2;
}

.inner-container {
  width: 100%;
  max-width: 1440px;
  margin: 0rem;
}

@media (max-width: 992px) {
  .inner-container {
    padding: 0rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;;EAEE,YAAA;AAAF;;AAGA;EACE,WAAA;EAEA,iBAAA;EACA,YAAA;AADF;;AAKA;EAEE;IACE,aAAA;EAHF;AACF","sourcesContent":["@import './view/styles/styles.scss';\n\n*:focus {\n  outline: none;\n}\n\nbutton:hover, input[type=submit]:hover {\n  opacity: 0.8;\n}\n\nbutton:active, input[type=submit]:active {\n  opacity: 0.6;\n}\n\nbutton:disabled:active, input[type=submit]:disabled:active,\nbutton:disabled:hover, input[type=submit]:disabled:hover {\n  opacity: 0.2;\n}\n\n.inner-container {\n  width: 100%;\n  //height: 100%;\n  max-width: 1440px;\n  margin: 0rem;\n  //overflow: hidden;\n}\n\n@media (max-width: $mediaLG) {\n\n  .inner-container {\n    padding: 0rem;\n  }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

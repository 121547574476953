import React from "react";

function Instagram(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29.05" height="29.044" viewBox="0 0 29.05 29.044">
      <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M14.523,9.313A7.446,7.446,0,1,0,21.97,16.76,7.435,7.435,0,0,0,14.523,9.313Zm0,12.288a4.841,4.841,0,1,1,4.841-4.841A4.85,4.85,0,0,1,14.523,21.6ZM24.011,9.009a1.737,1.737,0,1,1-1.737-1.737A1.733,1.733,0,0,1,24.011,9.009Zm4.932,1.763A8.6,8.6,0,0,0,26.6,4.686,8.652,8.652,0,0,0,20.511,2.34c-2.4-.136-9.585-.136-11.983,0a8.639,8.639,0,0,0-6.086,2.34A8.623,8.623,0,0,0,.1,10.765c-.136,2.4-.136,9.585,0,11.983a8.6,8.6,0,0,0,2.346,6.086,8.663,8.663,0,0,0,6.086,2.346c2.4.136,9.585.136,11.983,0A8.6,8.6,0,0,0,26.6,28.833a8.652,8.652,0,0,0,2.346-6.086c.136-2.4.136-9.579,0-11.977Zm-3.1,14.549a4.9,4.9,0,0,1-2.761,2.761c-1.912.758-6.448.583-8.561.583s-6.656.169-8.561-.583A4.9,4.9,0,0,1,3.2,25.321c-.758-1.912-.583-6.448-.583-8.561S2.449,10.1,3.2,8.2A4.9,4.9,0,0,1,5.962,5.438c1.912-.758,6.448-.583,8.561-.583s6.656-.169,8.561.583A4.9,4.9,0,0,1,25.845,8.2c.758,1.912.583,6.448.583,8.561S26.6,23.415,25.845,25.321Z" transform="translate(0.005 -2.237)" fill="#fff"/>
    </svg>
  );
}

export default Instagram;
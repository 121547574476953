import { Slider } from "../domain/models/sliderEntity";

export const sliderValues = {
  value: [100, 1000, 10000, 100000, 1000000],
  commission: [0.1, 0.2, 0.3, 0.4, 0.5]
}

export async function getSlider(): Promise<Slider> {
  return await sliderValues;
}

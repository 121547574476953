// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: "Space Grotesk", sans-serif;
}

body {
  margin: 0 auto !important;
  float: none !important;
  padding: 0;
  height: 100%;
  width: 100%;
  /*max-width: 1440px;
  /*overflow-y: hidden; /* Hide vertical scrollbar */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}`, "",{"version":3,"sources":["webpack://./src/index.scss","webpack://./src/view/styles/styles.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;EACA,aAAA;EACA,wCCLM;ADKR;;AAGA;EAEE,yBAAA;EACA,sBAAA;EACA,UAAA;EACA,YAAA;EACA,WAAA;EACA;oDAAA;EAGA,8JAAA;EAGA,mCAAA;EACA,kCAAA;AAJF;;AAOA;EACE,+EAAA;AAJF","sourcesContent":["@import './view/styles/styles.scss';\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  outline: none;\n  font-family: $font1;\n}\n\nbody {\n  //max-width: 1440px; \n  margin: 0 auto !important; \n  float: none !important; \n  padding: 0;\n  height: 100%;\n  width: 100%;\n  /*max-width: 1440px;\n  /*overflow-y: hidden; /* Hide vertical scrollbar */\n  //overflow-x: hidden;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n","// FONTS\n@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap');\n$font1: 'Space Grotesk', sans-serif;\n\n// COLORS\n$white: #FFFFFF;\n$white-background: #F7FBFE;\n$blue-logo: #0E2960;\n$blue-navbar: #2B364A;\n$blue-button: #196EF8;\n$blue-title: #0D1D48;\n$light-blue-background: #DEEBFF;\n$green-button: #28D386;\n\n// MARGIN PADDING\n$spaceXXXS: 0.25rem;\n$spaceXXS: 0.5rem;\n$spaceXS: 0.75rem;\n$spaceS: 1rem;\n$spaceM: 1.25rem;\n$spaceL: 1.5rem;\n$spaceXL: 2rem;\n$spaceXXL: 2.5rem;\n$spaceXXXL: 4rem;\n$spaceXXXXL: 6rem;\n\n// BORDER RADIUS\n$borderRadiusXxs: 4px;\n$borderRadiusXs: 8px;\n$borderRadiusMd: 25px;\n$borderRadiusLg: 45px;\n\n//MEDIA QUERIES\n$mediaXL: 1200px;\n$mediaLG: 992px;\n$mediaMD: 768px;\n$mediaXS: 576px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

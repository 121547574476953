import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import routes from './routes/routes';
import LandingPageView from './view/pages/LandingPageView';
import './App.scss';
import './view/styles/landing.scss';

function App() {

  return (
    <Routes>
      <Route path='' element={<Navigate replace to={ routes.landing }/>}/>
      <Route path={ routes.landing } element={ <LandingPageView/> } />
      <Route path={ "*" } element={ null }/>
    </Routes>
  );

}

export default App;

import React from 'react';
import ReadyImage from '../../../assets/colors_image.png';

export default function LandingThirdComponent({ model }: { 
  model: { ready1: string, ready2: string, ready3: string, readyButton: string } })
  : JSX.Element {

  return (
    <section 
      id="ready-landing-section" 
      className="ready-landing-container d-flex flex-column align-items-center"
    >
      <div className="ready-landing-title d-flex flex-column justify-content-center align-items-center">
        <h2>{ model.ready1 }</h2>
        <h4>{ model.ready2 }</h4>
      </div>
      <img src={ ReadyImage } alt={`ready-landing-section`} className=""/>
      <div className="ready-landing-buttons d-flex flex-column justify-content-center align-items-center">
        <h3>{ model.ready3 }</h3>
        <button>{ model.readyButton }</button>
      </div>
    </section>
  )

}

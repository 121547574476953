import React from "react";

function Features4Icon(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75">
      <g id="Grupo_237" data-name="Grupo 237" transform="translate(-430 -4753)">
        <circle id="Elipse_10" data-name="Elipse 10" cx="37.5" cy="37.5" r="37.5" transform="translate(430 4753)" fill="#ddebff"/>
        <path id="Icon_awesome-store-alt" data-name="Icon awesome-store-alt" d="M15.214,18.256H6.086V10.649H3.044V22.82a1.52,1.52,0,0,0,1.521,1.521h12.17a1.52,1.52,0,0,0,1.521-1.521V10.649H15.214ZM30.17,6.76,26.115.675A1.53,1.53,0,0,0,24.846,0H5.582A1.515,1.515,0,0,0,4.318.675L.263,6.76A1.521,1.521,0,0,0,1.527,9.128H28.906A1.524,1.524,0,0,0,30.17,6.76ZM24.342,23.58a.763.763,0,0,0,.761.761h1.521a.763.763,0,0,0,.761-.761V10.649H24.342Z" transform="translate(452.285 4778.33)" fill="#196ef8"/>
      </g>
    </svg>
  );
}

export default Features4Icon;
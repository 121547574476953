import React from "react";

function Features2Icon(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75">
      <g id="Grupo_235" data-name="Grupo 235" transform="translate(-923 -4434)">
        <circle id="Elipse_11" data-name="Elipse 11" cx="37.5" cy="37.5" r="37.5" transform="translate(923 4434)" fill="#ddebff"/>
        <g id="Icon_ionic-ios-stats" data-name="Icon ionic-ios-stats" transform="translate(942 4453)">
          <path id="Trazado_44" data-name="Trazado 44" d="M12.938,31.5h3.375a.564.564,0,0,0,.563-.562V5.063a.564.564,0,0,0-.562-.562H12.938a.564.564,0,0,0-.562.563V30.938A.564.564,0,0,0,12.938,31.5Z" fill="#196ef8"/>
          <path id="Trazado_45" data-name="Trazado 45" d="M6.188,31.5H9.563a.564.564,0,0,0,.563-.562V20.813a.564.564,0,0,0-.562-.562H6.188a.564.564,0,0,0-.562.563V30.938A.564.564,0,0,0,6.188,31.5Z" fill="#196ef8"/>
          <path id="Trazado_46" data-name="Trazado 46" d="M19.695,31.5h3.361a.567.567,0,0,0,.57-.57V16.32a.567.567,0,0,0-.57-.57H19.695a.567.567,0,0,0-.57.57V30.93A.567.567,0,0,0,19.695,31.5Z" fill="#196ef8"/>
          <path id="Trazado_47" data-name="Trazado 47" d="M25.875,9.57V30.93a.567.567,0,0,0,.57.57h3.361a.567.567,0,0,0,.57-.57V9.57a.567.567,0,0,0-.57-.57H26.445A.567.567,0,0,0,25.875,9.57Z" fill="#196ef8"/>
        </g>
      </g>
    </svg>
  );
}

export default Features2Icon;
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type { Slider } from "../../domain/models/sliderEntity";
import { getPricingUseCase } from "../../domain/useCases/getPricingUseCase";

function useLandingPageViewModel() {

   // eslint-disable-next-line
   const [t, i18n] = useTranslation("global");

   const [data, setData] = useState<Slider | undefined>(undefined);
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [error, setError] = useState<string>("");
 
  function getPricing() {
    setIsLoading(true);
      getPricingUseCase().then((data) => {
        setData(data)
        setIsLoading(false);
        setError("");
  })
}  

   
   return {
     title: t('landing.title'),
     subtitle: t('landing.subtitle'),
     getStarted: t('landing.getStarted'),
     poweredBy: t('landing.poweredBy'),
     welcome: t('landing.welcome'),
     welcomeMessage: t('landing.welcomeMessage'),
     welcomeMessageSpan: t('landing.welcomeMessageSpan'),
     welcomeButton: t('landing.welcomeButton'),
     welcomeButtonText: t('landing.welcomeButtonText'),
     ready1: t('landing.ready1'),
     ready2: t('landing.ready2'),
     ready3: t('landing.ready3'),
     readyButton: t('landing.readyButton'),
     featuresTitle: t('landing.featuresTitle'),
     featuresTitle1: t('landing.featuresTitle1'),
     featuresText1: t('landing.featuresText1'),
     featuresTitle2: t('landing.featuresTitle2'),
     featuresText2: t('landing.featuresText2'),
     featuresTitle3: t('landing.featuresTitle3'),
     featuresText3: t('landing.featuresText3'),
     featuresTitle4: t('landing.featuresTitle4'),
     featuresText4: t('landing.featuresText4'),
     featuresTitle5: t('landing.featuresTitle5'),
     featuresText5: t('landing.featuresText5'),
     featuresTitle6: t('landing.featuresTitle6'),
     featuresText6: t('landing.featuresText6'),
     magicTitle: t('landing.magicTitle'),
     magicText: t('landing.magicText'),
     pricingTitle: t('landing.pricingTitle'),
     pricingText: t('landing.pricingText'),
     limitlessTitle: t('landing.limitlessTitle'),
     limitlessText: t('landing.limitlessText'),
     limitlessTextSpan: t('landing.limitlessTextSpan'),
     limitless1: t('landing.limitless1'),
     limitless2: t('landing.limitless2'),
     limitless3: t('landing.limitless3'),
     limitless4: t('landing.limitless4'),
     limitless5: t('landing.limitless5'),
     successTitle: t('landing.successTitle'),
     successText: t('landing.successText'),
     journey: t('landing.journey'),
     journeyButton: t('landing.journeyButton'),
     slider: data ?? undefined,
     isLoading,
     error,
     getPricing
   };
 
 }
 
 export { useLandingPageViewModel };
import React from 'react';
import VideoLanding from '../../../assets/video_landing.mp4';
import PoweredBy from '../../../assets/powered_by.png';
import Video1 from '../../../assets/video1.png';
import Video2 from '../../../assets/video2.png';
import Video3 from '../../../assets/video3.png';
import Ready1 from '../../../assets/ready1.png';
import Ready2 from '../../../assets/ready2.png';

export default function LandingSecondComponent({ model }: { 
  model: { poweredBy: string; welcome: string; welcomeMessage: string; welcomeMessageSpan: string; welcomeButton: string; welcomeButtonText: string } })
  : JSX.Element {

  return (
    <section 
      id="video-landing-section" 
      className="video-landing-container d-flex flex-column align-items-center"
    >
      <img src={ Ready1 } alt={`video-landing-1`} className=""/>
      <img src={ Ready2 } alt={`video-landing-1`} className=""/>
      <div className="video-landing-video d-flex justify-content-center align-items-center">
        <img src={ Video1 } alt={`video-landing-1`} className=""/>
        <img src={ Video2 } alt={`video-landing-2`} className=""/>
        <img src={ Video3 } alt={`video-landing-3`} className=""/>
        <video controls width="auto" height="auto" title="video-landing-video" 
          className="video-landing-video" src={ VideoLanding } 
        ></video>
      </div>
      <div className="video-landing-powered d-flex justify-content-center align-items-center">
        <img src={ PoweredBy } alt={`ready-landing-section`} className=""/>
      </div>
      <div className="video-landing-text d-flex flex-column justify-content-center align-items-center">
        <h2>{ model.welcome }</h2>
        <h3>{ model.welcomeMessage }<span>{ model.welcomeMessageSpan }</span></h3>
      </div>
      <div className="video-landing-buttons d-flex flex-column justify-content-center align-items-center">
        <button>{ model.welcomeButton }</button>
        <p>{ model.welcomeButtonText }</p>
      </div>
    </section>
  )

}

import React from "react";

function Features5Icon(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75">
      <g id="Grupo_234" data-name="Grupo 234" transform="translate(-923 -4753)">
        <circle id="Elipse_12" data-name="Elipse 12" cx="37.5" cy="37.5" r="37.5" transform="translate(923 4753)" fill="#ddebff"/>
        <g id="Icon_feather-trending-up" data-name="Icon feather-trending-up" transform="translate(942.5 4773)">
          <path id="Trazado_50" data-name="Trazado 50" d="M34.5,9,20.25,23.25l-7.5-7.5L1.5,27" fill="none" stroke="#196ef8" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
          <path id="Trazado_51" data-name="Trazado 51" d="M25.5,9h9v9" fill="none" stroke="#196ef8" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
        </g>
      </g>
    </svg>
  );
}

export default Features5Icon;
import React from "react";

function LinkedIn(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.785" height="29.034" viewBox="0 0 28.785 29.034">
      <path id="Icon_awesome-linkedin" data-name="Icon awesome-linkedin" d="M26.728,2.25H2.05A2.074,2.074,0,0,0,0,4.343V29.191a2.074,2.074,0,0,0,2.05,2.093H26.728a2.08,2.08,0,0,0,2.056-2.093V4.343A2.08,2.08,0,0,0,26.728,2.25ZM8.7,27.136H4.433V13.28H8.706V27.136ZM6.566,11.388a2.5,2.5,0,1,1,2.474-2.5,2.486,2.486,0,0,1-2.474,2.5ZM24.692,27.136H20.425V20.4c0-1.607-.032-3.675-2.217-3.675-2.223,0-2.564,1.75-2.564,3.558v6.857H11.379V13.28h4.093v1.892h.058a4.487,4.487,0,0,1,4.041-2.236c4.318,0,5.121,2.871,5.121,6.6Z" transform="translate(0 -2.25)" fill="#fff"/>
    </svg>
  );
}

export default LinkedIn;
import React from "react";

function Features3Icon(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75">
      <g id="Grupo_232" data-name="Grupo 232" transform="translate(-1416 -4434)">
        <circle id="Elipse_13" data-name="Elipse 13" cx="37.5" cy="37.5" r="37.5" transform="translate(1416 4434)" fill="#ddebff"/>
        <g id="Icon_ionic-ios-cloud-upload" data-name="Icon ionic-ios-cloud-upload" transform="translate(1435 4455.25)">
          <path id="Trazado_48" data-name="Trazado 48" d="M30.734,11.658A10.546,10.546,0,0,0,11.573,6.8a4.984,4.984,0,0,0-1.891-.373,5.207,5.207,0,0,0-5.02,5.161A6.868,6.868,0,0,0,0,18.105,6.613,6.613,0,0,0,6.391,24.75H17.086V14.773l-3.368,3.319a.915.915,0,1,1-1.287-1.3l4.922-4.852h0a.894.894,0,0,1,.288-.19.872.872,0,0,1,.352-.07.916.916,0,0,1,.64.26l4.922,4.852a.915.915,0,1,1-1.287,1.3l-3.354-3.319V24.75H29.609a6.618,6.618,0,0,0,1.125-13.092Z" fill="#196ef8"/>
          <path id="Trazado_49" data-name="Trazado 49" d="M17.086,32.836a.914.914,0,0,0,1.828,0V24.75H17.086Z" fill="#196ef8"/>
        </g>
      </g>
    </svg>
  );
}

export default Features3Icon;